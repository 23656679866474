import request from "../utils/request";

// 获取文章列表
export function getArticleList(data = {}) {
  return request({
    url: '/article/api/portalArticleList',
    method: 'POST',
    data
  })
}

// 根据文章ID获取文章信息
export function articleInfo(id) {
  return request({
    url: `/article/api/info/${id}`,
    method: 'GET'
  })
}

// 获取排行榜的文章
export function getViewsArticle() {
  return request({
    url: `/article/api/getViewsArticle`,
    method: 'GET'
  })
}

// 获取当前文章的上一篇和下一篇
export function getNextAndPreviousArticle(articleId) {
  return request({
    url: `/article/api/getNextAndPreviousArticle/${articleId}`,
    method: 'GET'
  })
}

// 获取最新文章列表
export function getNewsArticleList() {
  return request({
    url: `/article/api/getNewArticleList`,
    method: 'GET'
  })
}

// 获取推荐文章
export function recommendArticle(articleId) {
  return request({
    url: `/article/api/getRecommendArticle/${articleId}`,
    method: 'GET'
  })
}

// 文章浏览量
export function articleViews(articleId) {
  return request({
    url: `/article/api/article_views/${articleId}`,
    method: 'GET'
  })
}

// 点赞文章
export function likeArticle(data) {
  return request({
    url: `/article/likeArticle`,
    method: 'POST',
    data
  })
}

// 收藏文章
export function collectArticle(data = {}) {
  return request({
    url: `/article/favorite`,
    method: 'POST',
    data
  })
}

// 分类列表
export function categoryList(data = {}) {
  return request({
    url: `/article/api/category/category_list`,
    method: 'POST',
    data
  })
}

// 根据分类ID查询分类信息
export function categoryInfo(categoryId) {
  return request({
    url: `/article/api/category/info/${categoryId}`,
    method: 'GET'
  })
}

// 根据分类ID查询分类信息
export function getArticlesByCategoryId(data = {}) {
  return request({
    url: `/article/api/getByCategoryId`,
    method: 'POST',
    data
  })
}

// 获取用户的文章数、分类数
export function getArticleCategoryNums(userId) {
  return request({
    url: `/article/api/getArticleCategoryNums`,
    method: 'GET',
    params: {
      userId
    }
  })
}

// 获取用户的文章
export function getUserArticle(data = {}) {
  return request({
    url: `/article/api/getUserArticle`,
    method: 'POST',
    data
  })
}

// 根据用户ID获取分类信息列表
export function getCategoryByUserId(data = {}) {
  return request({
    url: `/article/api/category/getCategoryByUserId`,
    method: 'POST',
    data
  })
}

// 查询用户的收藏文章
export function getFavoriteArticleByUserId(data = {}) {
  return request({
    url: `/article/api/getFavoriteArticleByUserId`,
    method: 'POST',
    data
  })
}

// 用户是否收藏该文章
export function isFavorite(articleId) {
  return request({
    url: `/article/isFavorite/${articleId}`,
    method: 'GET'
  })
}
